import React from 'react';

import './noise.less';

const Noise = () => {
  return (
    <div className="noise"></div>
  );
};

export default Noise;